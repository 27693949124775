import { z } from "zod"

export const noEmptyString = (value: unknown) =>
  value === "" ? undefined : value

const specialCharactersRegex = /(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~,])/
const lowercaseRegex = /(?=.*[a-z])/
const uppercaseRegex = /(?=.*[A-Z])/
const numberRegex = /(?=.*\d)/
const lengthRegex = /.{8,}/

export const passwordWithRegex = z
  .string({ required_error: "Password is required" })
  .regex(specialCharactersRegex, { message: " " })
  .regex(lowercaseRegex, { message: " " })
  .regex(uppercaseRegex, { message: " " })
  .regex(numberRegex, { message: " " })
  .regex(lengthRegex, { message: " " })

// NOTE: We may need to add password score back in, so leaving the code here for now

export type PasswordValidation = {
  hasLength: boolean | null
  hasSpecialCharacters: boolean | null
  hasLowercase: boolean | null
  hasUppercase: boolean | null
  hasNumber: boolean | null
  // passwordScore: number
}

export function validatePassword(password: string): PasswordValidation {
  const hasSpecialCharacters = specialCharactersRegex.test(password)
  const hasLowercase = lowercaseRegex.test(password)
  const hasUppercase = uppercaseRegex.test(password)
  const hasNumber = numberRegex.test(password)
  // const passwordScore = zxcvbn(password).score
  const hasLength = lengthRegex.test(password)

  return {
    hasLength,
    hasSpecialCharacters,
    hasLowercase,
    hasUppercase,
    hasNumber,
    // passwordScore,
  }
}
