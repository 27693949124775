import classNames from "classnames"

export interface SpinnerProps {
  spinnerClass?: string
}

export const Spinner = ({ spinnerClass }: SpinnerProps) => {
  return (
    <svg
      className={classNames("animate-spin fill-white", spinnerClass)}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#spinner)">
        <path d="M12.243 5.172l1.414-1.415a1 1 0 0 0-1.414-1.414l-1.415 1.414a1 1 0 0 0 1.415 1.415z" />
        <path
          opacity=".4"
          d="M13 9h2a1 1 0 1 0 0-2h-2a1 1 0 1 0 0 2zM13.657 12.243l-1.414-1.415a1 1 0 0 0-1.415 1.415l1.415 1.414a1 1 0 0 0 1.414-1.414zM7 13v2a1 1 0 1 0 2 0v-2a1 1 0 1 0-2 0zM3.757 13.657l1.415-1.414a1 1 0 1 0-1.415-1.415l-1.414 1.415a1 1 0 0 0 1.414 1.414zM3 7H1a1 1 0 0 0 0 2h2a1 1 0 0 0 0-2z"
        />
        <path
          opacity=".6"
          d="M5.172 3.757L3.757 2.343a1 1 0 1 0-1.414 1.414l1.414 1.415a1 1 0 1 0 1.415-1.415z"
        />
        <path opacity=".8" d="M9 3V1a1 1 0 0 0-2 0v2a1 1 0 0 0 2 0z" />
      </g>
      <defs>
        <clipPath id="spinner">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
