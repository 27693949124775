import { Spinner } from "./Spinner"

export default function SubmitButton({
  text,
  isLoading,
}: {
  text: string
  isLoading: boolean
}) {
  return (
    <button
      type="submit"
      disabled={isLoading}
      className="bg-primary-400 text-white font-extrabold flex items-center justify-center leading-7 h-10 border-r-2 py-1 hover:bg-primary-500"
    >
      {!isLoading ? text : <Spinner />}
    </button>
  )
}
