import { Field, Input, Label } from "@headlessui/react"
import type { InputProps } from "@headlessui/react"
import classNames from "classnames"
import React from "react"
import type { PropsWithChildren } from "react"

type LigInputProps = InputProps &
  PropsWithChildren & {
    labelText: string
    forgotPassword?: JSX.Element
    errorMessage?: string
  }

const LigInput = React.forwardRef<HTMLInputElement, LigInputProps>(
  (
    { labelText, forgotPassword, errorMessage, children, ...inputProps },
    ref,
  ) => {
    return (
      <Field className="flex flex-col">
        <Label
          className={classNames(
            "text-body-copy leading-7",
            forgotPassword && "flex justify-between",
          )}
        >
          {labelText}
          {forgotPassword}
        </Label>
        <Input
          ref={ref}
          className={classNames(
            "bg-smoke border-thin border-black/15 h-10 px-1 py-2 outline-none",
            errorMessage && "border-secondary-600",
          )}
          {...inputProps}
        />
        {errorMessage && (
          <span className="text-secondary-600 text-sm leading-6">
            {errorMessage}
          </span>
        )}
        {children}
      </Field>
    )
  },
)

LigInput.displayName = "LigInput"

export default LigInput
